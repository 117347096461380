import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Container, Form } from "react-bootstrap";
import { createUser, NewUserData, StepData } from "../../../actions/wp";
import Constants from "../../../constants";
import { initialValues } from "./data";
import * as CryptoJS from "crypto-js";
import { Form as FormikForm, Formik } from "formik";
import SignUpSteps from "../SignUpSteps/SignUpSteps";
// import FinalStep from "./elements/steps/FinalStep";
import StepFour from "./elements/steps/StepFour";
import StepOne from "./elements/steps/StepOne";
import StepThree from "./elements/steps/StepThree";
import StepTwo from "./elements/steps/StepTwo";
import { BackIcon } from "../../../assets/images/svg/Icons";
import Loader from "../../../components/Loader/Loader";
import FinalStep from "./elements/steps/FinalStep";

const AddUserForm: React.FC = () => {
  const dispatch = useDispatch();
  const [paymentSubmitting, setPaymentSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<StepData>(initialValues.stepData);

  const nextStep = (e: React.MouseEvent<HTMLButtonElement>, values: NewUserData) => {
    e.preventDefault();
    e.stopPropagation();

    const allowedProductMetaKeys = [1654, 1655];
    const hasAllowedProductMeta = Object.keys(values.productMetaData || {}).some((key) =>
      allowedProductMetaKeys.includes(Number(key))
    );
  
    if (hasAllowedProductMeta) {
      setStep(4);
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = (values: NewUserData) => {
    const allowedProductMetaKeys = [1654, 1655];
    const hasAllowedProductMeta = Object.keys(values.productMetaData || {}).some((key) =>
      allowedProductMetaKeys.includes(Number(key))
    );
  
    if (step === 4 && hasAllowedProductMeta) {
      setStep(2);
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [step]);

  const handleStepDataChange = (newData: Partial<StepData>) => {
    setUserData({ ...userData, ...newData });
  };

  const createPrzelewy24Transaction = async (transactionData: {
    merchantId: number;
    posId: number;
    sessionId: string;
    amount: number;
    currency: string;
    description: string;
    email: string;
    country: string;
    language: string;
    urlReturn: string;
    sign: string;
  }) => {
    const apiUrl = `${Constants.baseUri}/wp-json/myapp/v1/process-payment`;
    const username = `${Constants.p24_username}`;
    const password = `${Constants.p24_userpass}`;
    const basicAuth = "Basic " + btoa(username + ":" + password);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(transactionData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error("Błąd płatności:", data.message);
        } else {
          window.location.href = `https://secure.przelewy24.pl/trnRequest/${data.data.token}`;
        }
      })
      .catch((error) => {
        console.error("Błąd:", error);
      });
  };

  const fetchCartData = async (cartKey: any) => {
    const response = await fetch(
      `${Constants.baseUri}/wp-json/cocart/v2/cart/?cart_key=${cartKey}`
    );
    if (!response.ok) throw new Error("Problem z pobraniem danych koszyka");
    return await response.json();
  };

  const createOrder = async (
    userId: any,
    cartData: { items: { id: any; quantity: { value: any } }[] },
    userData: NewUserData
  ) => {
    const orderData = {
      customer_id: userId,
      payment_method: "p24",
      payment_method_title: "Przelewy24",
      set_paid: false,
      billing: {
        first_name: userData.first_name,
        last_name: userData.last_name,
        address_1: userData.address,
        address_2: "",
        city: userData.city,
        state: "",
        postcode: userData.postal_code,
        country: "PL",
        email: userData.email,
      },
      shipping: {
        first_name: userData.first_name,
        last_name: userData.last_name,
        address_1: userData.address,
        address_2: "",
        city: userData.city,
        state: "",
        postcode: userData.postal_code,
        country: "PL",
      },
      line_items: cartData.items.map(
        (item: { id: any; quantity: { value: any } }) => ({
          product_id: item.id,
          quantity: item.quantity.value,
        })
      ),
    };

    try {
      const response = await fetch(`${Constants.WC}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(
            `${Constants.consumer_key}:${Constants.consumer_secret}`
          )}`,
        },
        body: JSON.stringify(orderData),
      });

      const responseData = await response.json();
      if (!response.ok) {
        console.error("Problem z utworzeniem zamówienia:", responseData);
        return { error: true, data: responseData };
      }

      return { error: false, data: responseData };
    } catch (error) {
      console.error("Błąd przy tworzeniu zamówienia:", error);
      return { error: true, data: error };
    }
  };

  const transformLineItemsForSubscription = (cartLineItems: any[]) => {
    return cartLineItems.map((item) => {
      return {
        product_id: item.product_id,
        quantity: item.quantity,
        variation_id: item.variation_id || undefined,
      };
    });
  };

  const extractMetaDataValue = (key: string, metaData: any[]): string => {
    const foundItem = metaData.find((item) => item.key === key);
    return foundItem ? foundItem.value : "";
  };

  const createSubscription = async (
    customerId: any,
    orderKey: any,
    orderId: any,
    lineItems: any,
    values: any
  ) => {
    lineItems = transformLineItemsForSubscription(lineItems);

    let allProductMetaData: { key: any; value: any }[] = [];
    (Object.values(values.productMetaData) as any[][]).forEach(
      (productMetaData: any[]) => {
        productMetaData.forEach((md) => {
          allProductMetaData.push({ key: md.key, value: md.value });
        });
      }
    );

    const subscriptionFields = {
      billing_period: extractMetaDataValue(
        "_subscription_period",
        allProductMetaData
      ),
      billing_interval: parseInt(
        extractMetaDataValue(
          "_subscription_period_interval",
          allProductMetaData
        )
      ),
    };

    try {
      const response = await fetch(`${Constants.WC}/subscriptions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(
            `${Constants.consumer_key}:${Constants.consumer_secret}`
          )}`,
        },
        body: JSON.stringify({
          customer_id: customerId,
          order_key: orderKey,
          parent_id: orderId,
          line_items: lineItems,
          ...subscriptionFields,
        }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        console.error("Problem z utworzeniem subskrypcji:", responseData);
        return { error: true, data: responseData };
      }

      return { error: false, data: responseData };
    } catch (error) {
      console.error("Błąd przy tworzeniu subskrypcji:", error);
      return { error: true, data: error };
    }
  };

  const onSubmit = async (
    values: NewUserData,
    actions: { resetForm: () => void; setSubmitting: (arg0: boolean) => void }
  ) => {
    setPaymentSubmitting(true);
    try {
      const discountCode = values.discount?.toLowerCase();

      if (discountCode && discountCode !== "slimbox") {
        alert("Kod rabatowy nie istnieje");
        setPaymentSubmitting(false);
        actions.setSubmitting(false);
        return;
      }

      dispatch(
        createUser(values, async (res) => {
          if (res.status < 300) {
            const userId = res.data.id;
            const cartKey = values.cartKey;

            const cartData = await fetchCartData(cartKey);
            const orderResponse = await createOrder(userId, cartData, values);

            if (!orderResponse.error) {
              const subscriptionResponse = await createSubscription(
                userId,
                orderResponse.data.order_key,
                orderResponse.data.id,
                orderResponse.data.line_items,
                values
              );

              if (!subscriptionResponse.error) {
                let sessionId: string = `${subscriptionResponse.data.order_key}`;
                let merchantId: number = 235900;
                let amount: number = Math.round(
                  subscriptionResponse.data.total * 100
                );
                let currency: string = "PLN";
                let crc: string = "929c4f4c66ca54eb";

                let obj = {
                  sessionId: sessionId,
                  merchantId: merchantId,
                  amount: amount,
                  currency: currency,
                  crc: crc,
                };

                if (discountCode === "slimbox") {
                  amount = Math.ceil(amount * 0.8);
                  amount = Math.ceil(amount / 100) * 100;
                }

                let jsonStr = JSON.stringify(obj);
                let hash = CryptoJS.SHA384(jsonStr);
                let hashedStr = hash.toString(CryptoJS.enc.Hex);

                const transactionData = {
                  merchantId: 235900,
                  posId: 235900,
                  sessionId: sessionId,
                  amount: amount,
                  currency: "PLN",
                  description: sessionId,
                  email: values.email,
                  country: "PL",
                  language: "pl",
                  urlReturn: `https://healthandbodyacademy.com/dziekujemy?id=${orderResponse.data.id}&sessionId=${sessionId}`,
                  sign: hashedStr,
                };

                const transactionResponse = await createPrzelewy24Transaction(
                  transactionData
                );

                setStep(5);
                actions.resetForm();
                setPaymentSubmitting(false);
              } else {
                console.error(
                  "Błąd podczas tworzenia subskrypcji:",
                  subscriptionResponse.data
                );
              }
            } else {
              console.error(
                "Błąd podczas tworzenia zamówienia:",
                orderResponse.data
              );
            }
          } else {
            console.error("Błąd podczas tworzenia użytkownika:", res);
          }
        })
      );
    } catch (error) {
      console.error("Error:", error);
      setPaymentSubmitting(false);
      actions.setSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <StepOne onStepDataChange={handleStepDataChange} />;
      case 2:
        return <StepTwo onStepDataChange={handleStepDataChange} />;
      case 3:
        return <StepThree onStepDataChange={handleStepDataChange} />;
      case 4:
        return <StepFour />;
      case 5:
        return <FinalStep />;
      default:
        return null;
    }
  };

  return (
    <Container className="form-register__container">
      <SignUpSteps currentStep={step} />
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, values }) => (
          <FormikForm className="form-inner" data-step={step}>
            {renderStep()}
            {step !== 5 ? (
              <Form.Group className="form-buttons text-center">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => prevStep(values)}
                  disabled={step <= 1}
                >
                  <BackIcon />
                  Wróć
                </Button>
                {step < 4 ? (
                  <Button
                    disabled={
                      (step === 2 && !values.stepData.plan) ||
                      (step === 3 && !values.stepData.goal)
                    }
                    variant="primary"
                    type="button"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => nextStep(e, values)} // Precyzyjny typ dla `e`
                  >
                    Dalej
                  </Button>                
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={paymentSubmitting}
                  >
                    {paymentSubmitting ? (
                      <Loader />
                    ) : isSubmitting ? (
                      "TWORZENIE..."
                    ) : (
                      "UTWÓRZ KONTO I ZAPŁAĆ"
                    )}
                  </Button>
                )}
              </Form.Group>
            ) : (
              <div className="wr-buttons-final-step">
                <Link to="/">
                  <Button variant="secondary" className="btn-final-primary">
                    STRONA GŁOWNA
                  </Button>
                </Link>
                <Link to="/logowanie">
                  <Button variant="primary">PANEL KLIENTA</Button>
                </Link>
              </div>
            )}
          </FormikForm>
        )}
      </Formik>
    </Container>
  );
};

export default AddUserForm;
