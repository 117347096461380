import axios from "axios";
import { FC, useEffect, useState } from "react";
import Constants from "../../../../../constants";
import { NewUserData } from "../../../../../actions/wp";
import { useFormikContext } from "formik";
import { StepProps } from "../../interfaces";
import Products from "../../../../../components/Products/Products";
import Loader from "../../../../../components/Loader/Loader";

interface Product {
  id: number;
  name: string;
  meta_data: any[];
}

const StepTwo: FC<StepProps> = () => {
  const formik = useFormikContext<NewUserData>();
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickProduct = (name: string) => {
    formik.setFieldValue("stepData.plan", name);
  };

  const clearCart = async () => {
    setIsLoading(true);
    const clearCartUrl = `${Constants.baseUri}/wp-json/cocart/v2/cart/clear`;

    try {
      const response = await fetch(clearCartUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Problem with clearing the cart");
      }
      // console.log("Cart cleared");
      formik.setFieldValue("productMetaData", {});
    } catch (error) {
      // console.error("Error clearing cart:", error);
    }
    setIsLoading(false);
  };

  const addToCart = async (productId: number, quantity = 1) => {
    setIsLoading(true);
    await clearCart();

    const apiUrl = `${Constants.baseUri}/wp-json/cocart/v2/cart/add-item`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: productId.toString(),
          quantity: quantity.toString(),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const product = products.find((p) => p.id === productId);

        if (product) {
          formik.setFieldValue("cartKey", data.cart_key);
          formik.setFieldValue(
            `productMetaData.${productId}`,
            product.meta_data
          );
        } else {
          console.error("Product not found:", productId);
        }
      } else {
        console.error("Error adding product to cart: Response not OK");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
    setIsLoading(false);
  };

  const handleAddToCart = (productId: number) => {
    addToCart(productId);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${Constants.WC}/products?consumer_key=${Constants.consumer_key}&consumer_secret=${Constants.consumer_secret}&order=asc&orderby=price`
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="loading-overlay">
          <Loader />
        </div>
      )}
      <h2 className="form-heading text-center">
        WYBIERZ <span>TWÓJ</span> PAKIET
      </h2>
      <Products onClick={handleClickProduct} addToCart={handleAddToCart} />
    </div>
  );
};

export default StepTwo;
