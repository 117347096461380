import { FC } from "react";
import { StepProps } from "../../interfaces";
import Goals from "../../../../../components/Goals/Goals";
import { useFormikContext } from "formik";
import { NewUserData } from "../../../../../actions/wp";

const StepThree: FC<StepProps> = () => {
  const formik = useFormikContext<NewUserData>();

  const handleClickGoal = (name: string) => {
    formik.setFieldValue("stepData.goal", name);
  };

  return (
    <div>
      <h2 className="form-heading text-center">CO CHCESZ OSIĄGNĄĆ?</h2>
      <Goals onClick={handleClickGoal} />
    </div>
  );
};

export default StepThree;